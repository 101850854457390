<template>
    <div>
        <v-snackbar class="white--text text-center" top v-model="snackbar" :timeout="3000" :color="notificationType">{{notificationText}}</v-snackbar>
        <img class="d-block" width="300" :src="require('@/assets/logo.png')" alt="Group Trip">
        <h4 class="subheader subheaderGrey--text mt-2 ml-5 text-bold text-uppercase">Reset your Password</h4>

        <v-form v-if="!resetSuccess" v-model="valid" ref="resetPasswordForm" class="mt-5 mr-10">
          <div class="form-group">
              <label for="email" class="ml-5 paragraph--text">Email</label>
              <v-text-field height="60" id="email" outlined rounded type="email"
              v-model="email"
              :rules="[v => !!v || 'Email is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
              >
                  <template v-slot:prepend-inner>
                      <div class="field-item">
                          <v-icon color="forms">mdi-email-outline</v-icon>
                      </div>
                  </template>
              </v-text-field>
          </div>

          <div class="form-group">
            <label for="password" class="ml-5 paragraph--text">Password</label>
            <v-text-field id="password" outlined rounded type="password"
            v-model="password"
            :rules="[v => !!v || 'Password is required', v => v.length >= 8 || 'Password must be at least 8 characters']"
            >
              <template v-slot:prepend-inner>
                <div class="field-item">
                  <v-icon color="forms">mdi-lock-outline</v-icon>
                </div>
              </template>
            </v-text-field>
          </div>

          <div class="form-group">
            <label for="confirm-password" class="ml-5 paragraph--text">Confirm Password</label>
            <v-text-field id="confirm-password" outlined rounded type="password"
            v-model="password_confirmation"
            :rules="[v => !!v || 'Password confirmation is required', v => v === password || 'This does not match the passsword.']"
            >
              <template v-slot:prepend-inner>
                <div class="field-item">
                  <v-icon color="forms">mdi-lock-outline</v-icon>
                </div>
              </template>
            </v-text-field>
          </div>

          <v-btn :loading="loading" @click="resetPass" rounded color="pink white--text" block large>submit</v-btn>
        </v-form>

        <v-card outlined v-else class="mt-5 pt-5 pb-2">
            <h2 class="success--text text-center">
              Your password has been reset successfully
            </h2>
            <v-divider class="mt-2"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <router-link to='/auth/login'>login now</router-link>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import auth from '@/web_services/auth'

export default {
  data () {
    return {
      email: '',
      password: '',
      password_confirmation: '',
      valid: true,
      snackbar: false,
      notificationType: '',
      notificationText: '',
      loading: false,
      resetSuccess: false
    }
  },
  methods: {
    resetPass () {
      this.$refs.resetPasswordForm.validate()
      if (this.valid) {
        this.loading = true
        const body = {
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.$route.query.token
        }
        auth.resetPass(body).then(res => {
          this.resetSuccess = true
        }).catch(err => {
          this.showNotification('error', err.response.data.message)
        }).finally(() => {
          this.loading = false
        })
      }
    },
    showNotification (color, text) {
      this.snackbar = true
      this.notificationType = color
      this.notificationText = text
    }
  },
  created () {
    if (!this.$route.query.token) this.$router.push({ path: '/auth' })
  }
}
</script>

<style>
.v-text-field--outlined fieldset {
    border-color: #cecece !important;
}
.field-item {
    padding-right: 15px;
    border-right: 1px solid #cecece;
}
</style>
